<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="canEditKpi"
            variant="primary"
            :disabled="isAddRecordActive"
            :to="{ name: 'kpi-configuration'}"
          >
            <span>{{ $t('ADD_KPI') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="competition"
              :options="competitions"
              label="c_string_swe"
              :clearable="false"
              class="flex-grow-1 ml-50 mr-1"
              :placeholder="$t('SELECT_GAME')"
              @input="changeCompetitionClick()"
            />
          </div>

        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refKpiListTable"
      responsive
      :fields="kpiTableColumns"
      :items="kpis"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              variant="primary"
              :text="avatarText(data.item.name.substring(0,1))"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="$router.push({ name: 'kpi-configuration-id', params: { id: data.item.ID }})"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ strSubstring(data.item.kpi_typ,10) }}</small>
        </b-media>
      </template>

      <!-- Column:unit -->
      <template #cell(Unit)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.unit }}
          </span>
        </b-media>
      </template>
      <!-- Column:str1 -->
      <template #cell(Description)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.str1,10) }}
          </span>
        </b-media>
      </template>
      <!-- Column:str2 -->
      <template #cell(ShortDescription)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.str2,10) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            v-if="canEditKpi"
            :id="`game-row-${data.item.ID}-send-icon`"
            v-b-modal.kpi-modal
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'kpi-configuration-id', params: { id: data.item.ID }})"
          />

          <feather-icon
            :id="`game-row-${data.item.ID}-preview-icon`"
            v-b-modal.kpi-modal
            icon="AwardIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'game-view', params: { id: competition.encrypt_id }})"
          />

          <feather-icon
            v-if="canEditKpi"
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="Trash2Icon"
            class="cursor-pointer mr-1"
            size="16"
            @click="removeKpi(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalKpi"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BLink,
  BRow, BCol, BButton, BTable,
  BMedia,
  BPagination, BAvatar, VBPopover,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useKpiList from './useKpiList'
import constants from '@/constants/static.json'
import gameStore from '@/store/game/gameStore'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import { canEditDeleteGame } from '@/constants/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BAvatar,
    BLink,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      isAddRecordActive: true,
      canEditKpi: true,
      competitions: [],
      competition: useJwt.getGame(),
    }
  },
  created() {
    this.fetchCompetitions()
    this.changeCompetitionClick()
  },
  setup() {
    const { APP_STORE_MODULE } = constants
    const {
      fetchKpis,
      kpiTableColumns,
      perPage,
      currentPage,
      totalKpi,
      kpis,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refKpiListTable,
      statusFilter,
      refetchData,
    } = useKpiList()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      fetchKpis,
      kpiTableColumns,
      kpis,
      perPage,
      currentPage,
      totalKpi,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refKpiListTable,
      statusFilter,
      refetchData,
      avatarText,
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  methods: {
    fetchCompetitions() {
      store.dispatch('app-game/fetchAll').then(response => {
        this.competitions = response.data
        if (router.currentRoute.params.id) {
          this.competition = this.competitions.find(i => i.encrypt_id === router.currentRoute.params.id)
          this.fetchKpis(this.competition.encrypt_id)
          this.canEditKpi = canEditDeleteGame(this.competition)
          this.changeCompetitionClick()
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    removeKpi(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('KPI')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (data) {
              store.dispatch('app-game/deleteKpi',
                { competitionKpiId: data.ID })
                .then(response => {
                  if (response.data.success) {
                    this.successMessage(this.$i18n.t('MESSAGE.KPI_DELETED'))
                    this.fetchKpis(this.competition.encrypt_id)
                  }
                })
                .catch(() => {
                  this.showErrorMessage()
                })
            }
          }
        })
    },
    changeCompetitionClick() {
      if (this.competition.encrypt_id) {
        this.isAddRecordActive = false
        this.fetchKpis(this.competition.encrypt_id)
        useJwt.setGame(this.competition)
        this.canEditKpi = canEditDeleteGame(this.competition)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
