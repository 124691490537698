import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants/static.json'

export default function useKpiList() {
  // Use toast
  const { PER_PAGE_OPTIONS, PER_PAGE } = constants
  const refKpiListTable = ref(null)
  const toast = useToast()

  // Table Handlers
  const kpiTableColumns = [
    { key: 'Name', sortable: false },
    { key: 'Unit', sortable: false },
    { key: 'Description', sortable: false },
    { key: 'ShortDescription', sortable: false },
    { key: 'Actions' },
  ]
  const perPage = ref(PER_PAGE)
  const totalKpi = ref(0)
  const kpis = ref([])
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refKpiListTable.value ? refKpiListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalKpi.value,
    }
  })

  const refetchData = () => {
    refKpiListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchKpis = id => {
    store.dispatch('app-game/fetchKpis',
      id)
      .then(response => {
        const { result, count } = response.data
        kpis.value = result
        totalKpi.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'There was an issue with fetching the kpi list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchKpis,
    kpiTableColumns,
    perPage,
    currentPage,
    totalKpi,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refKpiListTable,
    statusFilter,
    refetchData,
    kpis,
  }
}
