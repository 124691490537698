// eslint-disable-next-line import/no-extraneous-dependencies
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllGame(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/all/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAll() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGames(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/allWithGroups/${queryParams.page}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategory() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/templates-category/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGameAll(ctx, queryParams) {
      let queryUrl = '/competitions'
      if (queryParams) {
        queryUrl = `/competitions?isArchive=${queryParams.isArchive}`
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}${queryUrl}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/company/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGameById(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/allWithGroups/1`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGoals(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/targets-kpi/src/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGoals(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/targets-kpi/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGoals(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/targets-kpi/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGoals(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/targets-kpi`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTaskSubTask(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/task/competition/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCompetition(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/competition/update`, { competition: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addKpi(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/competition/add/kpi`, { kpi: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteKpi(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/competition/delete/kpi`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/group/deleteGroupCompetition`, data)
          .then(response => resolve(response))
          .catch(error => reject(error.message))
      })
    },
    updateGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/group/update`, { group: data })
          .then(response => resolve(response))
          .catch(error => reject(error.message))
      })
    },
    addGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/group/add`, { group: data })
          .then(response => resolve(response))
          .catch(error => reject(error.message))
      })
    },
    fetchGame(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPerformedTask(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${params.id}/performedtasks?limit=10`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchKpis(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/${id}/kpi/1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
